import React from 'react'
import { css } from '@emotion/core'
import LogoHor from '../components/LogoHor'
import ContactForm from '../components/ContactForm'

export default (props) => {

    return (
        <div css={css`
          min-height: 100vh;
          background-image: url("/images/bg-muralista.png");
          background-size: cover;
          background-position: center;
          align-items: center;
          @media only screen and (max-width: 30em) {
            flex-direction: column;
          }
        `}>
          <LogoHor origin={props.location.state ? props.location.state.prevPath : '/'} />
          <div css={css`
            display: flex;
            min-height: 90vh;
          `}>
            <div css={css`
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2.5rem;
                @media only screen and (max-width: 26.5625em) {
                  display: none;
                }
            `}>
                <img css={css`
                    width: 800%;
                    max-width: 40rem;
                `} src="/images/muralista.png" alt="Muralista"/>
            </div>
            <div css={css`
              flex: 1;
              padding: 2.5rem;
              background-color: rgba(0,0,0,.3);
              display: flex;
              flex-direction: column;
              justify-content: center;
              @media only screen and (max-width: 26.5625em) {
                padding-top: 9rem;
              }
              ul {
                list-style: none;
              }
              p, li {
                font-size: 1.6rem;
                padding: 1rem;
                color: #fff;
              }
            `}>
              <p>MURALISTA busca tomar los espacios públicos de la CDMX y contar historias a través de sus paredes; que estas se vuelvan nuestros lienzos en gran formato y que los artistas dejen un mensaje en cada una de ellas para las personas que viven o caminan en sus alrededores.</p>
              <p>Se sabe que primero fue la pintura mural y después vino la pintura en lienzo y después todo lo demás. Queremos sacar el arte a las calles y hacerlo  público. </p>
              <p>Cada año se escogerá un tema y se invitará a distintos artistas a crear un mural a partir de la temática seleccionada bajo las siguientes 3 preguntas:</p>
              <ul>
                <li>. ¿El arte debería ocupar los espacios públicos?</li>
                <li>. ¿Qué puede significar una pared?</li>
                <li>. ¿Cómo la creación de un mural puede cambiar la definición de un muro como una construcción que sirve para delimitar o separar espacios?</li>
              </ul>
            </div>
          </div>
          <div css={css`
            padding: 4.5rem 2.5rem;
            background-color: var(--color-primary-dark);
            width: 100%;
            display: flex;
          `}>
            <div css={css`
              width: 95%;
              max-width: 40rem;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              h3 {
                font-size: 2rem;
                color: #fff;
                text-align: center;
              }
              form {
                display: flex;
                flex-direction: column;
                .input-item {
                  display: flex;
                  flex-direction: column;
                  padding: 1rem 0;
                  font-size: 1.4rem;
                  color: #fff;
                  input, textarea {
                    margin-top: .5rem;
                    padding: 1rem;
                    background-color: transparent;
                    border: 1px solid #fff;
                    outline: none;
                    color: #fff;
                  }
                }
                button {
                  padding: 1rem;
                  background-color: #fff;
                  color: var(--color-primary-dark);
                  text-transform: uppercase;
                }
              }
            `}>
              <h3>Conoce más de nuestros eventos</h3>
              <ContactForm page="Muralista" />
            </div>
          </div>
        </div>
    )
}